import React from "react";
import { Navigate } from "react-router-dom";

//Dashboard
import Dashboard from "../pages/Dashboard";

//UI Components

//Forms pages
// import FormElements from "src/pages/Forms/FormElements";
// import FormValidation from "src/pages/Forms/FormValidation";
// import AdvancedPlugins from "src/pages/Forms/AdvancedPlugins";
// import FormEditors from "src/pages/Forms/FormEditors";
// import FormUpload from "src/pages/Forms/FormUpload";
// import FormWizard from "src/pages/Forms/FormWizard";
// import FormMask from "src/pages/Forms/FormMask";
import Logout from "src/pages/Authentication/Logout";
import Login from "src/pages/Authentication/Login";

// // Categories
// import ListCategories from "src/pages/Categories/list-categories";
// import AddCategory from "src/pages/Categories/add-categories";
import Categories from "src/pages/Category/categories";
// import AddCategory from "src/pages/Category/add-category";
import AddCategory from "src/pages/Category/add-category";
// // Tracks
import ListTrack from "src/pages/Track/list-track";
import AddTrack from "src/pages/Track/add-track";

interface RouteProps {
  path: string;
  component: React.ReactNode;
  exact?: boolean;
}


const userRoutes: RouteProps[] = [
  // User Profile
  // { path: "/profile", component: <UserProfile /> },

  // Dashboard
  { path: "/dashboard", component: <Dashboard /> },
  { path: '/logout', component: <Logout /> },
  {path:"/login", component:<Login/>},

  
  //Categories
  { path: '/list-categories', component: <Categories /> },
  {path:'/add-categories', component:<AddCategory/>},
  // { path: '/add-category', component: <AddCategory /> },

  // Tracks
  { path: '/tracks', component: <ListTrack /> },
  { path: '/add-track', component: <AddTrack /> },

  // Forms pages
  // { path: "/form-elements", component: <FormElements /> },
  // { path: "/form-validation", component: <FormValidation /> },
  // { path: "/form-advanced", component: <AdvancedPlugins /> },
  // { path: "/form-editors", component: <FormEditors /> },
  // { path: "/form-uploads", component: <FormUpload /> },
  // { path: "/form-wizard", component: <FormWizard /> },
  // { path: "/form-mask", component: <FormMask /> },
  

  // Default route
  { path: "/", exact: true, component: <Navigate to="/login" /> },
];


export { userRoutes };
