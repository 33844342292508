import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import {
  Card,
  CardBody,
  CardTitle,
  Row,
  Col,
  Form,
  Label,
  Input,
  Breadcrumb,
  BreadcrumbItem,
} from "reactstrap";
import { BASE_URL } from 'src/constant';
const AddTrack = () => {
    const navigate = useNavigate();
    const [category, setCategory] = useState([]);
   
    const getCategory = async () => {
        const token = window.localStorage.getItem('token');
        const res = await axios.get(`${BASE_URL}category-list`, {
            headers: {
                Authorization: "Bearer " + token,
            }
        })
        console.log(res.data.category)
        setCategory(res.data.category);
      

    }
    
    useEffect(() => {
        getCategory();
    },[])

    const [result, setResult] = useState({
        track_name: "",
        category_id: "",
        duration: "",
        description: "",
        banner_image: null,
        gif_image: null,
        track:null
        
    })
    const getCategoryName = (e:any) => {
        console.log(e.target.value)
        category.map((val:any) => {
            if (e.target.value === val.category) {
                console.log('yes');
                console.log(val.category_id);
                setResult({...result,category_id:val.category_id})
            } else {
                console.log('no');
            }
        })
    }

    const handleChange = (e:any) => {
        if (e.target.name === 'track_name') {
            setResult({ ...result, track_name: e.target.value });
        } else if (e.target.name === 'track_description') {
            setResult({ ...result, description: e.target.value });

        } else if (e.target.name === 'track_duration') {
            setResult({ ...result, duration: e.target.value });
        } else if (e.target.name === 'banner_file') {
            setResult({ ...result, banner_image: e.target.files?.[0] });
        } else if (e.target.name === 'gif_file') {
            setResult({ ...result, gif_image: e.target.files?.[0] });
        } else if (e.target.name === 'track_file') {
            setResult({ ...result, track: e.target.files?.[0] });
        } else {
            console.log('not found');
        }

    }

  const handleAddTrack = async (e: any) => {
    e.preventDefault();
    console.log(result);
    const token = window.localStorage.getItem('token');
    const formData = {
      track_name: result.track_name,
      category_id: result.category_id,
      duration: result.duration,
      description: result.description,
      banner_image: result.banner_image,
      gif_image: result.gif_image,
      track: result.track
    }


    const res = await axios.post(`${BASE_URL}add-track`, formData,
      {
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "multipart/form-data",
        }
      }).then((response) => {
      if (response.status === 201) {
        toast.success("Category Added Successfully");
        navigate("/tracks");
      }
    }).catch((e) => {
      console.log(e.response.data.messages);
      if (e.response.status === 400) {
        // toast.error(res.data.message);
        console.log('hey');
        toast.error('Please Fill all the fields', {
          position: toast.POSITION.TOP_RIGHT,
        })
      } else if (e.response.status === 401) {
         toast.error('Unauthorized', {
          position: toast.POSITION.TOP_RIGHT,
        })
      }
        else { console.log('not found') }
      // console.log(res);
    });
    console.log(res);
    
         
  }
  return (
      <div className='page-content'>
          <ToastContainer/>
      <Breadcrumb>
        <BreadcrumbItem>
          <Link to="/tracks">List Track</Link>
        </BreadcrumbItem>
        <BreadcrumbItem active>Add Track</BreadcrumbItem>
      </Breadcrumb>
      <Card>
        <CardTitle>
          <h5 className="pt-4">Add Track</h5>
        </CardTitle>
        <CardBody>
          <Card>
            <CardBody>
              <div className="mt-3  d-flex justify-content-start">
                <Row>
                  <Col lg={12}>
                    <div>
                      <Form>
                        <Row>
                          <Col md={12}>
                            <div className="mb-3">
                              <Label
                                className="form-label"
                                htmlFor="formrow-firstname-input"
                              >
                                Track Name{" "}
                                <span className="text-danger">*</span>
                              </Label>
                              <Input
                                type="text"
                                className="form-control w-100 form-control-md"
                                id="formrow-firstname-input"
                                placeholder="Enter Track Name"
                                                              name="track_name"
                                                              onChange={handleChange}
                                
                              />
                            </div>
                          </Col>
                        </Row>

                        <Row>
                          <Col md={12}>
                            <div className="mb-3">
                              <Label
                                className="form-label"
                                htmlFor="formrow-email-input"
                              >
                                Track Description{" "}
                                <span className="text-danger">*</span>
                              </Label>
                              <Input
                                type="text"
                                className="form-control w-100 form-control-md"
                                id="formrow-email-input"
                                placeholder="Enter Track Description"
                                                              name="track_description"
                                                              onChange={handleChange}
                               
                              />
                            </div>
                          </Col>
                                              </Row>
                           <Row>
                          <Col md={12}>
                            <div className="mb-3">
                              <Label
                                className="form-label"
                                htmlFor="formrow-email-input"
                              >
                                Track Duration
                                <span className="text-danger">*</span>
                              </Label>
                              <Input
                                type="text"
                                className="form-control w-100 form-control-md"
                                id="formrow-email-input"
                                placeholder="Enter Track Duration"
                                                              name="track_duration"
                                                              onChange={handleChange}
                               
                              />
                            </div>
                          </Col>
                                              </Row>
                                              <Row>
                          <Col md={12}>
                            <div className="mb-3">
                              <Label
                                className="form-label"
                                htmlFor="formrow-email-input"
                              >
                                Select Category
                                <span className="text-danger">*</span>
                              </Label>
                             <Input
      id="exampleSelect"
      name="select"
                                                              type="select"
                                                              onChange={getCategoryName}
                                                          >
                                                              <option>Select Category</option>
         {
        category.map((val:any) => (
            <>
              <option key={val.category_id} value={val.category}>{val.category}</option>
            </>      
            )
                       
        )
      }
    </Input>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={12}>
                            <div className="mb-3">
                              <Label
                                className="form-label"
                                htmlFor="formrow-password-input"
                              >
                                Track Banner Image{" "}
                                <span className="text-danger">*</span>
                              </Label>
                              <Input
                                type="file"
                                className="form-control w-100 form-control-md"
                                id="formrow-password-input"
                                placeholder="select your file"
                                                              name="banner_file"
                                                              onChange={handleChange}
                                
                              />
                            </div>
                          </Col>
                        </Row>

                         <Row>
                          <Col md={12}>
                            <div className="mb-3">
                              <Label
                                className="form-label"
                                htmlFor="formrow-password-input"
                              >
                                Track GIF Image{" "}
                                <span className="text-danger">*</span>
                              </Label>
                              <Input
                                type="file"
                                className="form-control w-100 form-control-md"
                                id="formrow-password-input"
                                placeholder="select your file"
                                                              name="gif_file"
                                                              onChange={handleChange}
                                
                              />
                            </div>
                          </Col>
                                              </Row>
                                               <Row>
                          <Col md={12}>
                            <div className="mb-3">
                              <Label
                                className="form-label"
                                htmlFor="formrow-password-input"
                              >
                                Track 
                                <span className="text-danger">*</span>
                              </Label>
                              <Input
                                type="file"
                                className="form-control w-100 form-control-md"
                                id="formrow-password-input"
                                placeholder="select your file"
                                name="track_file"
                                onChange={handleChange}
                              />
                            </div>
                          </Col>
                        </Row>


                        <div className="mt-4">
                          <button
                            type="submit"
                            className="btn btn-primary w-md"
                            onClick={handleAddTrack}
                          >
                           Add Track
                          </button>
                        </div>
                      </Form>
                    </div>
                  </Col>
                </Row>
              </div>
            </CardBody>
          </Card>
        </CardBody>
      </Card>
    </div>
  )
}

export default AddTrack
