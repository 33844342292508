import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,

} from "reactstrap";

//i18n
import { withTranslation } from "react-i18next";
// Redux
import { Link } from "react-router-dom";

// users
import user1 from "../../../assets/images/users/avatar-1.jpg";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";



const ProfileMenu=()=>{
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState<boolean>(false);

  const [username, setusername] = useState("Admin");
  const navigate = useNavigate();
  //logout Function
  const handleLogout = () => {
    window.localStorage.removeItem('token');
  
    navigate('/logout');
  }

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item bg-soft-light border-start border-end"
          id="page-header-user-dropdown"
          tag="button"
        >
          <img
            className="rounded-circle header-profile-user"
            src={user1}
            alt="Header Avatar"
          />
          <span className="d-none d-xl-inline-block ms-2 me-1">{username}</span>
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
        </DropdownToggle>

        <DropdownMenu className="dropdown-menu-end">

          <Link to={"/contacts-profile"} className="dropdown-item">
            <i className="bx bx-user font-size-16 align-middle me-1" />{" "}
            Profile
          </Link>{" "}

         

          <div className="dropdown-divider" />
         
           <button onClick={handleLogout} className="btn btn-primary border-0 ms-4"> <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
            <span>Logout</span></button>
          
          
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
  }


export default ProfileMenu;
