import React, { useState } from "react";
import { Link } from "react-router-dom";

//Import Icons
import Icon from "@ailibs/feather-react-ts";

//import component

import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu";



//import images
import logoSvg from "../../assets/images/logo-sm.svg";
import sleep from '../../assets/images/sleep_logo.png';


const Header = (props: any) => {
  const [search, setsearch] = useState<boolean>(false);
  const [socialDrp, setsocialDrp] = useState<boolean>(false);

  function tToggle() {
    var body = document.body;
    body.classList.toggle("sidebar-enable");
    if (body.classList.contains("sidebar-enable")) {
      document.body.setAttribute("data-sidebar-size", "sm");
    } else {
      document.body.setAttribute("data-sidebar-size", "lg");
    }
  }

  return (
    <React.Fragment>
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex">
            <div className="navbar-brand-box">
              <Link to="/dashboard" className="logo logo-dark">
                <span className="logo-sm">
                  <img src={logoSvg} alt="" height="24" />
                </span>
                <span className="logo-lg">
                  <img src={sleep} alt="" height="45" width='150'/>{" "}
                  {/* <span className="logo-txt">Sleep Music App</span> */}
                </span>
              </Link>

              <Link to="/dashboard" className="logo logo-light">
                <span className="logo-sm">
                  <img src={logoSvg} alt="" height="24" />
                </span>
                <span className="logo-lg">
                  <img src={logoSvg} alt="" height="24" />{" "}
                  <span className="logo-txt">Sleep Music App</span>
                </span>
              </Link>
            </div>

            <button
              onClick={() => {
                tToggle();
              }}
              type="button"
              className="btn btn-sm px-3 font-size-16 header-item"
              id="vertical-menu-btn"
            >
              <i className="fa fa-fw fa-bars"></i>
            </button>

            <form className="app-search d-none d-lg-block">
              
            </form>
          </div>
          <div className="d-flex">
   
            <ProfileMenu />
          </div>
        </div>
      </header>
    </React.Fragment>
  );
};

export default Header;
