import React, { useEffect, useState } from 'react'
import { Card, CardBody, CardTitle } from 'reactstrap';
import { MDBDataTable } from 'mdbreact';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { BASE_URL } from 'src/constant';
const ListTrack = () => {
  const preview = (val:any) => {
    const URL = `https://sleep-music-app.lecenthealthcare.com//assets/uploads/banner/${val.banner_image}`;

    const url = URL;
    const a = document.createElement("a");

    a.href = url;
    a.target = "_blank";
    a.click();
  };
   const [result, setResult] = useState<any[]>([]);
  const getListTrack = async () => {
    const token = window.localStorage.getItem('token');
    const res = await axios.get(`${BASE_URL}track-list`, {
      headers: {
        Authorization: "Bearer " + token,
      }
    });
    console.log(res.data.track);
    setResult(res.data.track);
  }

  useEffect(() => {
    getListTrack();
  },[])


   const getAllRows = () => {
    const newArr: any[] = [];
    result.forEach((val: any, index: number) => {
      newArr.push({
        sr_no: index + 1,
        track_name: val.track_name,
        category: val.category,
        track_banner_image: (
          <>
          
            <img src={`https://sleep-music-app.lecenthealthcare.com//assets/uploads/banner/${val.banner_image}`} alt="" height={50} width={50} onClick={() => { preview(val) }}
           />
          </>
        ),
        
        actions: (
          <>
            <div className="d-flex justify-content-around">
                <button className="btn btn-md px-2 py-1 btn-outline-info rounded-pill"><i className="far fa-eye" title='view'></i></button>
              <button className="btn btn-md px-2 py-1 btn-outline-danger rounded-pill">
                <i className="mdi mdi-delete"  title="Delete"></i>
              </button>
            </div>
          </>
        ),
      });
    });
    return newArr;
  };
  const rowData = getAllRows();
   const data = {
    columns: [
      {
        label: "Sr No",
        field: "sr_no",
        sort: "asc",
        width: 200,
      },
      {
        label:"Track Name",
        field: "track_name",
        sort: "asc",
        width: 300,
      },
      {
        label: "Category",
        field: "category",
        sort: "asc",
        width: 200,
       },
             {
        label: "Track Banner Image",
        field: "track_banner_image",
        sort: "asc",
        width: 200,
       },
             
      {
        label: "Actions",
        field: "actions",
        sort: "asc",
        width: 200,
      },
     

    ],
    rows: rowData,
     
  };

  return (
     <div className='page-content'>
          <Card className='mt-5'>
            
          
              <CardTitle>
                  <div className='d-flex justify-content-between align-items-center'>
                      <p className='pt-3 h6'>List Tracks</p>
                     <Link to='/add-track'><button className='mt-3 btn btn-md btn-primary'><i className="fas fa-plus me-1"></i> Add Track</button></Link>
                  </div>
                  
                  </CardTitle>
              <CardBody>
                  
              <MDBDataTable data={data}  striped
            responsive
                      bordered
                      noBottomColumns
                      
            hover/>

              </CardBody>


          </Card>
      
    </div>
  )
}

export default ListTrack
