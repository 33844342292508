import React,{useState} from "react";
import { BASE_URL } from "src/constant";
import {
  Row,
  Col,
  Container,
  Form,
  Input,
  FormFeedback,
  Label,
  Alert,
} from "reactstrap";

import axios from 'axios';

import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import images
import logo from "../../assets/images/logo-sm.svg";

import CarouselPage from "../AuthenticationInner/CarouselPage";

const Login = () => {
  const navigate = useNavigate();
  document.title = "Login | Sleep Music App";
 const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const handleSubmit = async (e: any) => {
    e.preventDefault();
 
 
  
  
    const res = await axios.post(`${BASE_URL}login`,  { email, password }, {
      headers: {
        'Content-Type':'application/json'
      }
    }).then((response) => {
      if (response.status === 200)
      {
        console.log(response);
        
        navigate('/dashboard');
        window.localStorage.setItem('token', response.data.token);
         toast.success("Login Successfull 👍",{theme: "light",});
        }
    }).catch((e) => {
      console.log(e);
      if (email === "" && password === "") {
        toast.error("Please Fill All The fields", {
          position: toast.POSITION.TOP_RIGHT,
        })
      } else {
        toast.error("Invalid Credential", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    });
   
    // console.log(res.status);
    // if (res.status === 200)
    // {
    //    navigate('/dashboard');
    // toast.success("Login Successfull");
    // }
    // else if (res.status === 400) {
    //   console.log('hiii')
    //   return (<>
    //     <Alert>Invalid Credential</Alert>
    //   </>)
    //   // toast("Please enter correct credential.", {
    //   //   position: toast.POSITION.TOP_RIGHT,
    //   // });
    // } else {
    //   console.log("error 5000")
    // }
   
    // navigate('/dashboard');
}

  return (
    <React.Fragment>
      <div className="auth-page">
        <ToastContainer/>
        <Container fluid className="p-0">
          <Row className="g-0">
            <Col lg={4} md={5} className="col-xxl-3">
              <div className="auth-full-page-content d-flex p-sm-5 p-4">
                <div className="w-100">
                  <div className="d-flex flex-column h-100">
                    <div className="mb-4 mb-md-5 text-center">
                      <Link to="/dashboard" className="d-block auth-logo">
                        <img src={logo} alt="" height="28" />{" "}
                        <span className="logo-txt">Sleep Music</span>
                      </Link>
                    </div>
                    <div className="auth-content my-auto">
                      <div className="text-center">
                        <h5 className="mb-0">Welcome Back !</h5>
                        <p className="text-muted mt-2">
                          Sign in to continue to Sleep Music.
                        </p>
                      </div>
                      <Form
                        className="custom-form mt-4 pt-2" method="#"
                        onSubmit={handleSubmit}
                      >
                        <div className="mb-3">
                          <Label className="form-label">Email</Label>
                          <Input
                            name="text"
                            className="form-control"
                            placeholder="Enter email"
                                value={email}
                              onChange={(e) => setEmail(e.target.value)}
                            type="email"
                          />
                        </div>

                        <div className="mb-3">
                          <Label className="form-label">Password</Label>
                          <Input
                            name="text"
                            type="password"
                            placeholder="Enter Password"
                            value={password}
            onChange={(e) => setPassword(e.target.value)}
                          />
                        </div>

                        <div className="row mb-4">
                          <div className="col">
                            <div className="mt-3 d-grid">
                             
                              <button
                                  className="btn btn-primary btn-block"
                                 type="submit"
                                >
                               Log In
                              </button>
                            </div>
                          </div>
                        </div>
                      </Form>
                    </div>
                    <div className="mt-4 mt-md-5 text-center">
                      <p className="mb-0">
                        © {new Date().getFullYear()} Sleep Music . Crafted with{" "}
                        <i className="mdi mdi-heart text-danger"></i> by
                        Divine Infosys
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <CarouselPage />
          </Row>
        </Container>
      </div>
   
    </React.Fragment>
  );
};

export default Login;

