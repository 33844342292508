import React from "react";


import { Card, CardBody, Col, Container, Row } from "reactstrap";

/** import Mini Widget data */




// import Widgets from "./Widgets";
import CountUp from "react-countup";
import ReactApexChart from "react-apexcharts";

// import common data
import { WidgetsData } from "../../common/data/dashboard";
import { ToastContainer } from "react-toastify";



//meta title

const Dashboard = () => {
  document.title="Dashboard | Sleep Music App";
  return (
    <React.Fragment>
      <div className="page-content">
        <ToastContainer/>
        <Container fluid>
          {/* Render Breadcrumbs */}
         

          <Row>
           
              <Col xl={3} md={6}>
                <Card className="card-h-100 border-1 border-primary">
                  <CardBody>
                    <Row className="align-items-center">
                      <Col xs={6}>
                        <span className="text-muted mb-3 lh-1 d-block text-truncate">
                          Total Tracks
                        </span>
                        <h4 className="mb-3">
                         
                          <span className="counter-value">
                            <CountUp
                              start={0}
                              end={20}
                              duration={5}
                            />
                           
                          </span>
                        </h4>
                      </Col>
                      <Col xs={6}>
                        
                      </Col>
                    </Row>
                   
                  </CardBody>
                </Card>
              </Col>
          <Col xl={3} md={6}>
                <Card className="card-h-100 border-1 border-primary">
                  <CardBody>
                    <Row className="align-items-center">
                      <Col xs={6}>
                        <span className="text-muted mb-3 lh-1 d-block text-truncate">
                          Total Categories
                        </span>
                        <h4 className="mb-3">
                         
                          <span className="counter-value">
                            <CountUp
                              start={0}
                              end={25}
                              duration={5}
                            />
                           
                          </span>
                        </h4>
                      </Col>
                      <Col xs={6}>
                        
                      </Col>
                    </Row>
                   
                  </CardBody>
                </Card>
              </Col>
          <Col xl={3} md={6}>
                <Card className="card-h-100 border-1 border-primary">
                  <CardBody>
                    <Row className="align-items-center">
                      <Col xs={6}>
                        <span className="text-muted mb-3 lh-1 d-block text-truncate">
                          Total Users
                        </span>
                        <h4 className="mb-3">
                         
                          <span className="counter-value">
                            <CountUp
                              start={0}
                              end={30}
                              duration={5}
                            />
                           
                          </span>
                        </h4>
                      </Col>
                      <Col xs={6}>
                        
                      </Col>
                    </Row>
                   
                  </CardBody>
                </Card>
              </Col>
          <Col xl={3} md={6}>
                <Card className="card-h-100 border-1 border-primary">
                  <CardBody>
                    <Row className="align-items-center">
                      <Col xs={6}>
                        <span className="text-muted mb-3 lh-1 d-block text-truncate">
                          Total Tracks
                        </span>
                        <h4 className="mb-3">
                         
                          <span className="counter-value">
                            <CountUp
                              start={0}
                              end={20}
                              duration={5}
                            />
                           
                          </span>
                        </h4>
                      </Col>
                      <Col xs={6}>
                        
                      </Col>
                    </Row>
                   
                  </CardBody>
                </Card>
              </Col>
         
          </Row>
          
          
          
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Dashboard;