import axios from "axios";
// import { rmSync } from "fs";
import React, { useState } from "react";
import { Link, useNavigation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import {
  Card,
  CardBody,
  CardTitle,
  Row,
  Col,
  Form,
  Label,
  Input,
  Breadcrumb,
  BreadcrumbItem,
} from "reactstrap";
import { useNavigate } from "react-router-dom";
import { BASE_URL } from "src/constant";
const AddCategory = () => {
  const navigate = useNavigate();

  const [result, setResult] = useState({
    category_name: "",
    category_description: "",
    category_image: null,
  });

  const handleChange = (e:any) => {
    // const { name, value, files } = e.target;
    if (e.target.name === "category_name") {
      setResult({ ...result, category_name: e.target.value });
    } else if (e.target.name === "category_description") {
      setResult({ ...result, category_description: e.target.value });
    } else if (e.target.name === "cat_file") {
      setResult({ ...result, category_image: e.target.files?.[0] });
    } else {
      console.log("not found");
    }
  };

  console.log(result);

  const handleAddCategory = async (e: any) => {
    e.preventDefault();
    // console.log('hiiii');
    // const files = [];
    // files.push(result.category_image);
    // console.log(files);

    const token = window.localStorage.getItem("token");
    // const formData = new FormData();
    // formData.append("category", result.category_name);
    // formData.append("description", result.category_description);
    // formData.append("image", result.category_image);


    const formData = {
      category: result.category_name,
      description: result.category_description,
      image: result.category_image
    }
  
    const res = await axios.post(
      `${BASE_URL}add-category`,
      formData,
      {
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "multipart/form-data",
        },
      }
    ).then((response) => {
      if (response.status === 201) {
        toast.success("Category Added Successfully");
        navigate("/list-categories");
      }
    }).catch((e) => {
      console.log(e.response.data.messages);
      if (e.response.status === 400) {
        // toast.error(res.data.message);
        console.log('hey');
        toast.error('Please Fill all the fields', {
          position: toast.POSITION.TOP_RIGHT,
        })
      } else if (e.response.status === 401) {
         toast.error('Unauthorized', {
          position: toast.POSITION.TOP_RIGHT,
        })
      } else { console.log('not found'); }
      // console.log(res);
    });
  }

  return (
    <div className="page-content">
      <ToastContainer/>
      <Breadcrumb>
        <BreadcrumbItem>
          <Link to="/list-categories">List Category</Link>
        </BreadcrumbItem>
        <BreadcrumbItem active>Add Category</BreadcrumbItem>
      </Breadcrumb>
      <Card>
        <CardTitle>
          <h5 className="pt-4">Add Category</h5>
        </CardTitle>
        <CardBody>
          <Card>
            <CardBody>
              <div className="mt-3  d-flex justify-content-start">
                <Row>
                  <Col lg={12}>
                    <div>
                      <Form>
                        <Row>
                          <Col md={12}>
                            <div className="mb-3">
                              <Label
                                className="form-label"
                                htmlFor="formrow-firstname-input"
                              >
                                Category Name{" "}
                                <span className="text-danger">*</span>
                              </Label>
                              <Input
                                type="text"
                                className="form-control w-100 form-control-md"
                                id="formrow-firstname-input"
                                placeholder="Enter Category Name"
                                name="category_name"
                                required={true}
                                value={result.category_name}
                                onChange={handleChange}
                              />
                            </div>
                          </Col>
                        </Row>

                        <Row>
                          <Col md={12}>
                            <div className="mb-3">
                              <Label
                                className="form-label"
                                htmlFor="formrow-email-input"
                              >
                                Category Description{" "}
                                <span className="text-danger">*</span>
                              </Label>
                              <Input
                                type="text"
                                className="form-control w-100 form-control-md"
                                id="formrow-email-input"
                                placeholder="Enter Category Description"
                                name="category_description"
                                required={true}
                                value={result.category_description}
                                onChange={handleChange}
                              />
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={12}>
                            <div className="mb-3">
                              <Label
                                className="form-label"
                                htmlFor="formrow-password-input"
                              >
                                Category Image{" "}
                                <span className="text-danger">*</span>
                              </Label>
                              <Input
                                type="file"
                                className="form-control w-100 form-control-md"
                                id="formrow-password-input"
                                placeholder="select your file"
                                required={true}
                                name="cat_file"
                                onChange={handleChange}
                              />
                            </div>
                          </Col>
                        </Row>

                        <div className="mt-4">
                          <button
                            type="submit"
                            className="btn btn-primary w-md"
                            onClick={handleAddCategory}
                          >
                            Submit
                          </button>
                        </div>
                      </Form>
                    </div>
                  </Col>
                </Row>
              </div>
            </CardBody>
          </Card>
        </CardBody>
      </Card>
    </div>
  );
};

export default AddCategory;
