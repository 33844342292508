import PropTypes from "prop-types";
import React, { useEffect, useRef } from "react";


// Layout Related Components
import Header from "./Header";
import Sidebar from "./Sidebar";
import Footer from "./Footer";


//redux

const Layout = (props: any) => {
  const ref = useRef<any>();


  // const {
  //   isPreloader,
  //   layoutWidth,
  //   topbarTheme,
  //   leftSideBarTheme,
  //   layoutMode,
  //   layoutType,
  //   leftSideBarType,
  //   showRightSidebar,
  // } = 

  const isMobile: any = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

 

  //hides right sidebar on body click
  const hideRightbar = (event: any) => {
    var rightbar = document.getElementById("right-bar");
    //if clicked in inside right bar, then do nothing
    if (rightbar && rightbar.contains(event.target)) {
      return;
    } else {
      //if clicked in outside of rightbar then fire action for hide rightbar
 
    }
  };

  
   

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


 

  


  /*
  call dark/light mode
  */
  

  return (
    <React.Fragment>
      <div className="pace pace-active" id="preloader" ref={ref}>
        <div
          className="pace-progress"
          data-progress-text="100%"
          data-progress="99"
          style={{ transform: "translate3d(100%, 0px, 0px)" }}
        >
          <div className="pace-progress-inner"></div>
        </div>
        <div className="pace-activity"></div>
      </div>

      <div id="layout-wrapper">
        <Header
         
        />
        <Sidebar
          
          isMobile={isMobile}
        />
        <div className="main-content">
          {props.children}
          <Footer />
        </div>
      </div>
      
    </React.Fragment>
  );
};



export default Layout;
