import React from "react";
import { Routes, Route } from "react-router-dom";

// Import Routes all
import { userRoutes } from "./routes/allRoutes";

// layouts Format
import VerticalLayout from "./components/VerticalLayout/";

// Import scss
import "./assets/scss/theme.scss";
// import "./assets/scss/preloader.scss";

const App = () => {
  const Layout = VerticalLayout;

  return (
     <React.Fragment>
    <Routes>
      {userRoutes.map((route, idx) => (
        <Route
          path={route.path}
          element={
            (route.path === "/login" || route.path === "/logout") ? (
              route.component
            ) : (
              <Layout>{route.component}</Layout>
            )
          }
          key={idx}
        />
      ))}
    </Routes>
  </React.Fragment>






  );
};

export default App;