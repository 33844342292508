import React, { useEffect } from "react";




//redux

import { Col, Container, Row } from "reactstrap";
import { Link } from "react-router-dom";
import CarouselPage from "../AuthenticationInner/CarouselPage";
import logo from "../../assets/images/sleep_logo.png";
import { ToastContainer } from "react-toastify";


const Logout = () => {
  

 
  return (
    <div className="container-fluid p-0">
      <ToastContainer/>
      <Container fluid>
        <Row className="row g-0">
          <Col xxl={3} lg={4} md={5}>
            <div className="auth-full-page-content d-flex p-sm-5 p-3">
              <div className="w-100">
                <div className="d-flex flex-column h-100">
                  <div className="mb-2 mb-md-2 text-center">
                    <Link to="index.html" className="d-block auth-logo">
                      <img src={logo} alt="" height="38" />{" "}
                      <span className="logo-txt">Sleep Music</span>
                    </Link>
                  </div>
                  <div className="auth-content my-auto">
                    <div className="text-center">
                      <div className="avatar-xl mx-auto">
                        <div className="avatar-title bg-light-subtle text-primary h1 rounded-circle">
                          <i className="bx bxs-user"></i>
                        </div>
                      </div>

                      <div className="mt-2 pt-2">
                        <h5>You are Logged Out</h5>
                        <p className="text-muted font-size-15">
                          Thank you for using{" "}
                          <span className="fw-semibold text-dark">Sleep Music</span>
                        </p>
                        <div className="mt-4">
                          <Link
                            to="/login"
                            className="btn btn-primary w-100 waves-effect waves-light"
                          >
                            Sign In
                          </Link>
                        </div>
                      </div>
                    </div>

                    
                  </div>
                  <div className="mt-1 mt-md-2 text-center">
                    <p className="mb-0">
                      © {new Date().getFullYear()} Sleep Music . Crafted with{" "}
                      <i className="mdi mdi-heart text-danger"></i> by
                      Divine Infosys
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <CarouselPage />
        </Row>
      </Container>
    </div>
  );
};

export default Logout;
