import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { MDBDataTable } from "mdbreact";
import { Card, CardBody, CardTitle } from "reactstrap";
import axios from "axios";
import '../../assets/scss/custom.scss';
import { ToastContainer } from "react-toastify";
import { BASE_URL } from "src/constant";

const Categories: React.FC = () => {


   const preview = (val:any) => {
    const URL = `https://sleep-music-app.lecenthealthcare.com/assets/uploads/category/${val.image}`;

    const url = URL;
    const a = document.createElement("a");

    a.href = url;
    a.target = "_blank";
    a.click();
  };
  const [result, setResult] = useState<any[]>([]);

  const getCategories = async () => {
    const token = window.localStorage.getItem("token");
    const res = await axios.get(`${BASE_URL}category-list`, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    console.log(res.data.category);
    setResult(res.data.category);
  };

  useEffect(() => {
    getCategories();
  }, []);

  const getAllRows = () => {
    const newArr: any[] = [];
    result.forEach((val: any, index: number) => {
      newArr.push({
        sr_no: index + 1,
        category_name: val.category,
        category_description: val.description,
        category_image: (
          <>
          
            <img src={`https://sleep-music-app.lecenthealthcare.com/assets/uploads/category/${val.image}`} alt="" height={50} width={50} onClick={() => { preview(val) }}
           />
          </>
        ),
        actions: (
          <>
            <div className="d-flex justify-content-center">
              <button className="btn btn-md px-2 py-1 btn-outline-danger rounded-pill">
                <i className="fas fa-trash-alt"  title="Delete"></i>
              </button>
            </div>
          </>
        ),
      });
    });
    return newArr;
  };
  const rowData = getAllRows();
  const data = {
    columns: [
      {
        label: "Sr No",
        field: "sr_no",
        sort: "asc",
        width: 200,
      },
      {
        label: "Category Name",
        field: "category_name",
        sort: "asc",
        width: 300,
      },
      {
        label: "Category Description",
        field: "category_description",
        sort: "asc",
        width: 200,
      },
      {
        label: "Category Image",
        field: "category_image",
        sort: "asc",
        width:200,
},
      {
        label: "Actions",
        field: "actions",
        sort: "asc",
        width: 200,
      },
    ],
    rows: rowData,
  };

  return (
    <div className="page-content">
      <ToastContainer autoClose={5000}/>
      <Card className="mt-3">
        <CardTitle>
          <div className="d-flex justify-content-between align-items-center">
            <p className="pt-3 h4">List Categories</p>
            <Link to="/add-categories">
              <button className="mt-3 btn btn-md btn-primary">
                <i className="fas fa-plus me-1"></i> Add Category
              </button>
            </Link>
          </div>
        </CardTitle>
        <CardBody>
          <MDBDataTable data={data} striped responsive bordered noBottomColumns hover />
        </CardBody>
      </Card>
    </div>
  );
};

export default Categories;
